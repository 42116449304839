/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'category',
    label: 'Complete Bikes',
    type: 'SelectSingleFilterNested',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',
      default_key: 'roadBikes,gravelBikes,bmxBikes,eBikeSlashCargoBikes,trackBikes,mtbBikes',
      options: [
        {key: 'roadBikes', label: 'Road Bikes'},
        {key: 'gravelBikes', label: 'Gravel Bikes'},
        {key: 'bmxBikes', label: 'BMX Bikes'},
        {key: 'eBikeSlashCargoBikes', label: 'E-Bike/Cargo Bikes'},
        {key: 'trackBikes', label: 'Track Bikes'},
        {key: 'mtbBikes', label: 'MTB Bikes'}
      ]
    },
  },
  {
    id: 'category',
    label: 'Framesets',
    type: 'SelectSingleFilterNested',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      default_key: 'roadFrames,gravelFrames,bmxFrames,mtbFrames,trackFrames',
      schemaType: 'enum',
      options:
        [
          {key: 'roadFrames', label: 'Road Frames'},
          {key: 'gravelFrames', label: 'Gravel Frames'},
          {key: 'bmxFrames', label: 'BMX Frames'},
          {key: 'mtbFrames', label: 'MTB Frames'},
          {key: 'trackFrames', label: 'Track Frames'}
        ]
    },
  },
  {
    id: 'category',
    label: 'Forks & Suspension',
    type: 'SelectSingleFilterNested',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      default_key: 'carbonForks,suspensionForks,rearShocks,bmxForks',
      schemaType: 'enum',
      options:
        [
          {key: 'carbonForks', label: 'Carbon Forks'},
          {key: 'suspensionForks', label: 'Suspension Forks'},
          {key: 'rearShocks', label: 'Rear Shocks'},
          {key: 'bmxForks', label: 'BMX Forks'}
        ]
    },
  },
  {
    id: 'category',
    label: 'Wheels & Tires',
    type: 'SelectSingleFilterNested',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      schemaType: 'enum',
      default_key: 'roadWheels,gravelWheels,mtbWheels,trackWheels,roadTires,gravelTires,bmxTires,mtbTires,tubes,wheelAccessories',
      options: [
        {key: 'roadWheels', label: 'Road Wheels', },
        {key: 'gravelWheels', label: 'Gravel Wheels'},
        {key: 'mtbWheels', label: 'MTB Wheels'},
        {key: 'trackWheels', label: 'Track Wheels'},
        {key: 'roadTires', label: 'Road Tires'},
        {key: 'gravelTires', label: 'Gravel Tires'},
        {key: 'bmxTires', label: 'BMX Tires'},
        {key: 'mtbTires', label: 'MTB Tires'},
        {key: 'tubes', label: 'Tubes'},
        {key: 'wheelAccessories', label: 'Wheel Accessories'}
      ]
    },
  },
  {
    id: 'category',
    label: 'Cockpit',
    type: 'SelectSingleFilterNested',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      schemaType: 'enum',
      default_key: 'handlebars,stems,saddles,seatposts,seatpost-clamps,bar-tape,mtb-slash-bmx-grips,droppers,small-parts',
      options: [
        {key: 'handlebars', label: 'Handlebars'},
        {key: 'stems', label: 'Stems'},
        {key: 'saddles', label: 'Saddles'},
        {key: 'seatposts', label: 'Seatposts'},
        {key: 'seatpostClamps', label: 'Seatpost Clamps'},
        {key: 'barTape', label: 'Bar Tape'},
        {key: 'mtbSlashBmxGrips', label: 'MTB/BMX Grips'},
        {key: 'droppers', label: 'Droppers'},
        {key: 'smallParts', label: 'Small Parts'}
      ]
    },
  },
  {
    id: 'category',
    label: 'Drivetrain',
    type: 'SelectSingleFilterNested',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      schemaType: 'enum',
      default_key: 'groupsets,cranksets,chains,shifters,derailleurs,cassettes,bottomBrackets,brakes,pedals,powerMeters',
      options: [
        {key: 'groupsets', label: 'Groupsets'},
        {key: 'cranksets', label: 'Cranksets'},
        {key: 'chains', label: 'Chains'},
        {key: 'shifters', label: 'Shifters'},
        {key: 'derailleurs', label: 'Derailleurs'},
        {key: 'cassettes', label: 'Cassettes'},
        {key: 'bottomBrackets', label: 'Bottom Brackets'},
        {key: 'brakes', label: 'Brakes'},
        {key: 'pedals', label: 'Pedals'},
        {key: 'powerMeters', label: 'Power Meters'}
      ]
    },
  },
  {
    id: 'category',
    label: 'Accessories',
    type: 'SelectSingleFilterNested',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config:
    {
      schemaType: 'enum',
      default_key: 'indoorTrainers,computersAndElectronics,pumpsAndC02,bagsAndPacks,lights,bottleCages,bidonsSlashBottles,tools,other',
      options: [
        {key: 'indoorTrainers', label: 'Indoor Trainers'},
        {key: 'computersAndElectronics', label: 'Computers , Electronics'},
        {key: 'pumpsAndC02', label: 'Pumps, C02'},
        {key: 'bagsAndPacks', label: 'Bags, Packs'},
        {key: 'lights', label: 'Lights'},
        {key: 'bottleCages', label: 'Bottle Cages'},
        {key: 'bidonsSlashBottles', label: 'Bidons/Bottles'},
        {key: 'tools', label: 'Tools'},
        {key: 'other', label: 'Other'},
      ]
    },
  },
  {
    id: 'category',
    label: 'Apparel',
    type: 'SelectSingleFilterNested',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config:
    {
      schemaType: 'enum',
      default_key: 'jerseys,bibShortsAndTights,sunglasses,helmets,shoes,socks,jacketsAndGilets,warmers,caps,gloves,protection,casualwear',
      options: [
        {key: 'jerseys', label: 'Jerseys'},
        {key: 'bibShortsAndTights', label: 'Bib Shorts & Tights'},
        {key: 'sunglasses', label: 'Sunglasses'},
        {key: 'helmets', label: 'Helmets'},
        {key: 'shoes', label: 'Shoes'},
        {key: 'socks', label: 'Socks'},
        {key: 'jacketsAndGilets', label: 'Jackets & Gilets'},
        {key: 'warmers', label: 'Warmers'},
        {key: 'caps', label: 'Caps'},
        {key: 'gloves', label: 'Gloves'},
        {key: 'protection', label: 'Protection'},
        {key: 'casualwear', label: 'Casualwear'}
      ]
    }
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 50000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    {key: 'createdAt', label: 'Newest'},
    {key: '-createdAt', label: 'Oldest'},
    {key: '-price', label: 'Lowest price'},
    {key: 'price', label: 'Highest price'},

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)'},
  ],
};

export const condition = {
  id: 'condition',
  label: 'Condition',
  config: {
    schemaType: 'enum',
    default_key: '',
    placeholder: "",
    required: true,
    options: [
      {key: 'new', label: 'New'},
      {key: 'usedLikeNew', label: 'Used - Like New'},
      {key: 'usedGoodCondition', label: 'Used - Good Condition'},
      {key: 'usedFairCondition', label: 'Used - Fair Condition'},
      {key: 'usedWellUsed', label: 'Used - Well Used'},
    ]
  }
};

export const wheelSize = {
  id: 'wheelSize',
  label: 'Wheel Size',
  config: {
    schemaType: 'enum',
    default_key: '',
    options: [
      {key: '20', label: '20"'},
      {key: '26', label: '26"'},
      {key: '27_5_650b', label: '27.5" / 650b'},
      {key: '29', label: '29"'},
      {key: '700c', label: '700c'},
      {key: 'other', label: 'Other'}
    ]
  },
}

export const size = {
  id: 'size',
  label: 'Size',
  config: {
    schemaType: 'enum',
    default_key: '',
    options: [
      {key: 'xs', label: 'XS'},
      {key: 's', label: 'S'},
      {key: 'm', label: 'M'},
      {key: 'l', label: 'L'},
      {key: 'xl', label: 'XL'},
      {key: 'xxl', label: 'XXL'},
      {key: 'other', label: 'Other'}
    ]
  },
};

export const frameSize = {
  id: 'frameSize',
  label: 'Frame Size',
  config: {
    schemaType: 'enum',
    default_key: '',
    options: [
      {key: 'xs', label: 'XS'},
      {key: 's', label: 'S'},
      {key: 'm', label: 'M'},
      {key: 'l', label: 'L'},
      {key: 'xl', label: 'XL'},
      {key: 'xxl', label: 'XXL'},
      {key: 'other', label: 'Other'}
    ]
  },
};

export const frameMaterial = {
  id: 'frameMaterial',
  label: 'Frame Material',
  config: {
    schemaType: 'enum',
    default_key: '',
    options: [
      {key: 'aluminum', label: 'Aluminum'},
      {key: 'carbonFiber', label: 'Carbon Fiber'},
      {key: 'chromoly', label: 'Chromoly'},
      {key: 'steel', label: 'Steel'},
      {key: 'titanium', label: 'Titanium'},
      {key: 'other', label: 'Other'}
    ]
  },
};

export const config_for_listings = [condition, wheelSize, size, frameSize, frameMaterial];
export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['category', 'condition', 'brand', 'size', "model",
    "year",
    "colour",
    'frameMaterial',
    'frameSize',
    'wheelSize',
    "groupset",
    "wheelset",],
};
