import { PrimaryButton } from '../Button/Button';
import React, { useState } from 'react';
import PropTypes, { bool, func } from 'prop-types';
import PaypalOnboarding from './PaypalOnboarding';
import { Modal } from '../../components';


const OffboardModal = props => {


  const {
    className,
    onClose,
    isOpen,
    offboardAccount,
    closeButtonMessage
  } = props;
  const onManageDisableScrolling = function(){};

  return (
    <Modal
      id="OffboardPaypalModal"
      isOpen={!!isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      offboardAccount={offboardAccount}
      closeButtonMessage={closeButtonMessage}
    >
      Disconnecting your PayPal account will prevent you from offering PayPal services and products
      on your website. Do you wish to continue?
      <PrimaryButton
        onClick={offboardAccount}>
        Proceed & Unlink My Account From PayPal
      </PrimaryButton>
    </Modal>
  )
}


OffboardModal.defaultProps = {
  className: null,
  onClose:null,
  isOpen:false,
  offboardAccount:null,
  closeButtonMessage:"Cancel"
};

const { string } = PropTypes;

OffboardModal.propTypes = {
  className: string,
  onClose:func,
  isOpen:bool,
  offboardAccount:func,
  closeButtonMessage:string
};

export default OffboardModal;
