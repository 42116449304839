import { LOCATION_CHANGED } from '../ducks/Routing.duck';
import { LOGIN_COMPLETE } from '../ducks/Auth.duck';
import { UPDATE_PROFILE_SUCCESS } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { KlaviyoSdk } from './klaviyo';
// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
// - trackPageView(canonicalPath, previousPath): called when the URL is changed
export const createMiddleware = (handlers, sdk) => store => next => action => {
  const { type, payload } = action;


  if (type === LOCATION_CHANGED) {
    const previousPath = store?.getState()?.Routing?.currentCanonicalPath;
    const { canonicalPath } = payload;
    handlers.forEach(handler => {
      handler.trackPageView(canonicalPath, previousPath);
    });
  }
  if((type === LOGIN_COMPLETE) || (type ===UPDATE_PROFILE_SUCCESS)){
    KlaviyoSdk.identifyUser(sdk,store.getState().user);
  }

  if(process.env.REACT_APP_KLAVIYO_ENABLED && type === LOCATION_CHANGED)
  {
    if(sdk?.currentUser  && store.getState()?.Auth?.isAuthenticated){
      const klaviyoId= store.getState()?.user?.currentUser?.attributes.profile.privateData.klaviyoId;
      if(klaviyoId){
        KlaviyoSdk.trackEvent(store.getState()?.user?.currentUser?.attributes.email, action);
          }

    }
  }


  next(action);
};

