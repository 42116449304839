import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';
import { ExternalLink } from '../index';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: July 1st, 2021</p>
      <h2>Overview</h2>
      <p>This website is operated by WeCycle Inc. (“WeCycle”). Throughout the site, the terms “we”, “us” and “our” refer to WeCycle. WeCycle offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated herein. WeCycle is hosted by Sharetribe Oy (Business ID 2432359-2), a limited liability company incorporated and existing under the laws of Finland, having its principal place of business at Erottajankatu 19 B, 00130 Helsinki, Finland ("Sharetribe"). Sharetribe provides us with the online platform that allows us to provide our services to you.
      </p>
      <h2>1.  PRIVACY NOTICE</h2>
      <p>
        Our mission is to bring confidence and user-friendliness to the current buy and sell cycling marketplace. Therefore, it is essential that the information you submit through the service be made available to other users in your country and around the world. In using our Service, your information may also be shared with other services of which you are not a user, as well as potentially transmitted to third parties as set forth herein.<br/><br/>
        WeCycle is committed to protecting the privacy of its users and the information that you share in connection with your use of our Services. This privacy policy (“Privacy Policy”) describes the privacy practices of WeCycle with respect to information, including personally identifiable data, personally identifiable information, personal information, or similar identifiable data that identifies you as an individual personally as defined under applicable law (“Personal Data”) that we collect about individuals (“you”), including through any of our websites, mobile applications, or any other services or offerings which may reference this Privacy Policy (the “Services”)(all of the your data or information received by, or generated through your use of, the WeCycle services, including Personal Data defined broadly herein as “Information”). Any Services may also provide additional policies which will govern how WeCycle processes the information with respect to that specific product, service, or promotion. By submitting Information through our Services, you agree to the terms of this Privacy Policy, our Terms of Service as well as any other documentation referenced herein. If you do not agree you should discontinue use of the services immediately. WeCycle retains the right to deny or suspend access to anyone we reasonably believe has violated any provision of this Privacy Policy.
      </p>
      <h2>2.  VISITORS </h2>
      <p>
        WeCycle does not collect information from not logged in visitors interacting with the site. A cookie and IP address may be tracked to allow technology to better the visitor experience during a session. Session data is cleared when a browser is closed.
      </p>
      <h2>3.  USER</h2>
      <p>
        WeCycle offers the ability to become a user of the website through listing your item(s) and/or creating an account. Users provide their email address and other personal information during the listing process. Your email address is never shown to visitors or other users of the community. Age, gender, and IP information may be used as an identifier during third-party advertising. WeCycle never shares your email address with any third party. As a user of WeCycle, you may upload your photos. Your uploaded material is viewable by all visitors and users. You may, at any time and through contacting us at info@wecycleshop.com, ask to delete or remove your uploaded material. You may only upload material that is your own, or you have the copyright to upload.
      </p>
      <h2>4.  ANALYTICS</h2>
      <p>
        We use analytics partners (such as Google Analytics) to help analyze usage and traffic for our Services. As an example, we may use analytics partners to analyze and measure, in the aggregate, the number of unique visitors to our Services.
      </p>
      <h2>5.  THIRD-PARTY ADVERTISERS, LINKS TO OTHER SITES</h2>
      <p>
        WeCycle may allow other companies, called third-party ad servers or ad networks, to serve advertisements on the WeCycle platform. These third-party ad servers or ad networks use technology to send, directly to your browser, the advertisements and links that appear on WeCycle. They automatically receive your IP address when this happens. They may also use other technologies (such as cookies, JavaScript, or Web Beacons) to measure the effectiveness of their advertisements and to personalize the advertising content you see.<br/><br/>
        WeCycle does not provide any personally identifiable information to these third-party ad servers or ad networks without your consent or except as part of a specific program or feature for which you will have the ability to opt-in or opt-out. However, please note that if an advertiser asks WeCycle to show an advertisement to a certain audience (for example, men aged 18-34) or audience segment (for example, men aged 18-24 who have viewed certain channels of content) and you respond to that advertisement, the advertiser or ad-server may conclude that you fit the description of the audience they are trying to reach. The advertiser may also use information regarding your use of WeCycle, such as the number of times you viewed an ad (but not any personally identifiable information), to determine which ads to deliver to you.
      </p>
      <h2>6.  UPLOADED AND LISTED INFORMATION</h2>
      <p>
        By submitting or posting content on our Services, you grant us a worldwide, non-exclusive, transferable license to use, redistribute, prepare derivative works and sublicense the IP. The above license would terminate once your content has been removed, unless it has already been shared elsewhere.
      </p>
      <h2>7.  HOW WE PROTECT PERSONAL INFORMATION</h2>
      <p>
        We use commercially reasonable safeguards to help keep the information collected through the Site secure. We also take appropriate measures to help ensure that our service providers with access to Personal Information provide an appropriate level of protection. Despite these efforts to store Personal Information in a secure operating environment that is not available to the public, we cannot guarantee the security of Personal Information during its transmission or its storage on our systems. Further, while we attempt to ensure the integrity and security of Personal Information, we cannot guarantee that our security measures will prevent third parties such as so-called hackers from illegally obtaining access to Personal Information. We do not represent or warrant that Personal Information about you will be protected against, loss, misuse, or alteration by third parties.
      </p>
      <h2>8.  CHILDREN’S PRIVACY</h2>
      <p>
        WeCycle does not knowingly collect or solicit any Personal Information from children. In the event that we learn that we have collected Personal Information from a child without parental consent, we will promptly take steps to delete that information.
      </p>
      <h2>9.  OTHER WEBSITES AND SERVICES</h2>
      <p>
        We are not responsible for the practices employed by any websites or services linked to or from our Site, including the information or content contained within them.  We encourage you to investigate and ask questions before disclosing your Personal Information to third parties.
      </p>
      <h2>10.  CHANGES TO OUR PRIVACY POLICY</h2>
      <p>WeCycle Shop may modify or update this Privacy Policy from time to time, so please review it periodically. We may provide you with notice of material changes to the Privacy Policy as appropriate under the circumstances. Unless otherwise indicated, any changes to this Privacy Policy will apply immediately upon posting to the Site.</p>
      <h2>11.  CONTACT INFORMATION</h2>
      <p>
        Questions about the Privacy Policy should be sent to us at <ExternalLink href="mailto:info@wecycleshop.com">info@wecycleshop.com</ExternalLink>.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
