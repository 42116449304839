import React from 'react';
import PropTypes, { bool,func} from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { ExternalLink, LinkTabNavHorizontal, NamedLink } from '../../components';
import { PayPalScriptProvider, PayPalButtons, FUNDING} from "@paypal/react-paypal-js";
import { PrimaryButton,SecondaryButton } from '../Button/Button';
import css from './PaypalOnboarding.module.css';
import config from '../../config';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  PaypalSettingsPageComponent
} from '../../containers/PaypalSettingsPage/PaypalSettingsPage';



const PaypalOnboarding = props => {




  const { className, rootClassName, selectedPageName, action_url,merchantId,paypalOnboarded,merchantIdQueried,paypalInfoQueried,isExpressCheckoutActive,isPaymentsReceivable, hasOAuthPermissions,isPrimaryEmailValidated,
  showOffboardingModal,offboardAccount,showOffboardingModalValue,setOffboardingFalse,onCloseModal} = props;
  const classes = classNames(rootClassName || css.root, className);
  const paypalLink = <ExternalLink href="https://www.paypal.com/">PayPal</ExternalLink>;

  const paypalBusinessSettingsLink = <ExternalLink href="https://www.paypal.com/businessprofile/settings">PayPal Settings</ExternalLink>;
  return (
    <div>
      {merchantIdQueried  && (merchantId && isExpressCheckoutActive) ?
    (<div>

        { (!isPaymentsReceivable ?
        <div>
          <h3><FormattedMessage id="PaypalSettingsPage.paymentsReceivableMissing"
                                values={{paypalLink: paypalLink}}/></h3>
        </div>
        : null )}
      {(!isPrimaryEmailValidated ?
        <div>
          <h3><FormattedMessage id="PaypalSettingsPage.primaryEmailNotValidated"
                                values={{paypalLink: paypalBusinessSettingsLink}}/></h3>
        </div>
      : null )}
      {((paypalOnboarded && isExpressCheckoutActive) ?
        <div>

      <h3><FormattedMessage id="PaypalSettingsPage.onboardedMessage"/> <ExternalLink href="https://www.paypal.com/">PayPal</ExternalLink>.</h3>
        <h3>
          <PrimaryButton
          className={css.paypalDisableButton}
          onClick={showOffboardingModal}
          > Unlink PayPal Account </PrimaryButton>
        </h3>
    </div>
        : null )}

      </div>
    )

: <div>
        { (!(action_url === "") ?
    <div>
      <PayPalScriptProvider>
        <FormattedMessage id="PaypalSettingsPage.onboardingLinkMessage"/>
          <a className={css.paypalOnboardingButton} onClick={setOffboardingFalse} data-paypal-button="true" href={action_url+"&displayMode=minibrowser"} target="PPFrame">Sign Up As A PayPal Merchant</a>

      </PayPalScriptProvider>
    </div>
   :
      (paypalInfoQueried ?
      <div>
      <h3>Something went wrong connecting to PayPal. Please refresh the page. If this error persists please contact us at <ExternalLink href="mailto:info@wecycleshop.com">info@wecycleshop.com</ExternalLink>.</h3>
    </div>
      :
          <div>
            <h3>Loading your Paypal Account Information...Please Wait.</h3>
          </div>
      )
      )}</div>}
    </div>
      );
};

PaypalOnboarding.defaultProps = {
  className: null,
  rootClassName: null,
  action_url: null,
  merchantId: null,
  paypalInfoQueried: false,
  showOffboardingModal:null,
  offboardAccount:null,
  showOffboardingModalValue:false,
  onCloseModal:null,
  setOffboardingFalse:null
};

const { string } = PropTypes;

PaypalOnboarding.propTypes = {
  className: string,
  rootClassName: string,
  action_url: string,
  merchantId: string,
  isPaymentsReceivable:bool,
  isPrimaryEmailValidated:bool,
  isExpressCheckoutActive:bool,
  hasOAuthPermissions:bool,
  showOffboardingModal: func,
  offboardAccount:func,
  showOffboardingModalValue: bool,
  onCloseModal:func,
  setOffboardingFalse: func
};



export default PaypalOnboarding;
