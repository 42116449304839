import pick from 'lodash/pick';
import config from '../../config';
import {
  initiatePrivileged,
  transitionPrivileged,
  createPaypalOrder,
  getPaypalOrder,
  transactionLineItems,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {  lineItemsTotal } from '../../components/OrderBreakdown/LineItemSubTotalMaybe'
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CANCEL_PAYMENT,
  isPrivileged, TRANSITION_EXPIRE_PAYMENT,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import {
  getPaypalOnboardingLinkFunc,
  tryGetMerchantStatus, tryGetSellerStatus,
} from '../../ducks/paypalOnboarding.duck';
import Decimal from 'decimal.js';
import {
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_SHIPPING_FEE, LINE_ITEM_TAX,
  LINE_ITEM_UNITS,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';
export const INITIATE_PAYPAL_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_PAYPAL_ORDER_REQUEST';
export const INITIATE_PAYPAL_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_PAYPAL_ORDER_SUCCESS';
export const INITIATE_PAYPAL_ORDER_ERROR = 'app/CheckoutPage/INITIATE_PAYPAL_ORDER_ERROR';
export const GET_PAYPAL_ORDER_REQUEST = 'app/CheckoutPage/GET_PAYPAL_ORDER_REQUEST';
export const GET_PAYPAL_ORDER_SUCCESS = 'app/CheckoutPage/GET_PAYPAL_ORDER_SUCCESS';
export const GET_PAYPAL_ORDER_ERROR = 'app/CheckoutPage/GET_PAYPAL_ORDER_ERROR';


export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const EXPIRE_PAYMENT_REQUEST = 'app/CheckoutPage/EXPIRE_PAYMENT_REQUEST';
export const EXPIRE_PAYMENT_SUCCESS = 'app/CheckoutPage/EXPIRE_PAYMENT_SUCCESS';
export const EXPIRE_PAYMENT_ERROR = 'app/CheckoutPage/EXPIRE_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const PAYPAL_CUSTOMER_REQUEST = 'app/CheckoutPage/PAYPAL_CUSTOMER_REQUEST';
export const PAYPAL_CUSTOMER_SUCCESS = 'app/CheckoutPage/PAYPAL_CUSTOMER_SUCCESS';
export const PAYPAL_CUSTOMER_ERROR = 'app/CheckoutPage/PAYPAL_CUSTOMER_ERROR';

export const PAYPAL_SELLER_REQUEST = 'app/CheckoutPage/PAYPAL_SELLER_REQUEST';
export const PAYPAL_SELLER_SUCCESS = 'app/CheckoutPage/PAYPAL_SELLER_SUCCESS';
export const PAYPAL_SELLER_ERROR = 'app/CheckoutPage/PAYPAL_SELLER_ERROR';


// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  paypalCustomerFetched: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload, submitting:false };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case INITIATE_PAYPAL_ORDER_REQUEST:
      return { ...state, initiatePaypalOrderError: null};
    case INITIATE_PAYPAL_ORDER_SUCCESS:
      return { ...state, paypalOrder: payload,submitting:false };
    case INITIATE_PAYPAL_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiatePaypalOrderError: payload };

    case GET_PAYPAL_ORDER_REQUEST:
      return { ...state, getPaypalOrderError: null };
    case GET_PAYPAL_ORDER_SUCCESS:
      return { ...state, paypalOrder: payload };
    case GET_PAYPAL_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, getPaypalOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };
    case EXPIRE_PAYMENT_REQUEST:
      return { ...state, expirePaymentError: null };
    case EXPIRE_PAYMENT_SUCCESS:
      return state;
    case EXPIRE_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, expirePaymentError: payload };

    case PAYPAL_CUSTOMER_REQUEST:
      return { ...state, paypalCustomerFetched: false };
    case PAYPAL_CUSTOMER_SUCCESS:
      return { ...state, paypalCustomerFetched: true };
    case PAYPAL_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, paypalCustomerFetchError: payload };

    case PAYPAL_SELLER_REQUEST:
      return { ...state, paypalSellerFetched: false };
    case PAYPAL_SELLER_SUCCESS:
      return { ...state, paypalSellerFetched: true };
    case PAYPAL_SELLER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, paypalCustomerFetchError: payload };


    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const initiatePaypalOrderRequest = () => ({ type: INITIATE_PAYPAL_ORDER_REQUEST });

const initiatePaypalOrderSuccess = order => ({
  type: INITIATE_PAYPAL_ORDER_SUCCESS,
  payload: order,
});

const initiatePaypalOrderError = e => ({
  type: INITIATE_PAYPAL_ORDER_ERROR,
  error: true,
  payload: e,
});

const getPaypalOrderRequest = () => ({ type: GET_PAYPAL_ORDER_REQUEST });

const getPaypalOrderSuccess = order => ({
  type: GET_PAYPAL_ORDER_SUCCESS,
  payload: order,
});

const getPaypalOrderError = e => ({
  type: GET_PAYPAL_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

const expirePaymentRequest = () => ({ type: EXPIRE_PAYMENT_REQUEST });

const expirePaymentSuccess = orderId => ({
  type: EXPIRE_PAYMENT_SUCCESS,
  payload: orderId,
});

const expirePaymentError = e => ({
  type: EXPIRE_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const paypalCustomerRequest = () => ({ type: PAYPAL_CUSTOMER_REQUEST });
export const paypalCustomerSuccess = () => ({ type: PAYPAL_CUSTOMER_SUCCESS });
export const paypalCustomerError = e => ({
  type: PAYPAL_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const paypalSellerRequest = () => ({ type: PAYPAL_SELLER_REQUEST });
export const paypalSellerSuccess = () => ({ type: PAYPAL_SELLER_SUCCESS });
export const paypalSellerError = e => ({
  type: PAYPAL_SELLER_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiatePaypalOrder = (orderParams, transactionId,initiateSuccess) => (dispatch, getState, sdk) => {
  dispatch(initiatePaypalOrderRequest());

  const handleSuccess = response => {
    dispatch(initiatePaypalOrderSuccess({payload:response}));
    dispatch(initiateOrder(orderParams,null,response.id,initiateSuccess));
    return response.id;
  };

  const handleError = e => {
    dispatch(initiatePaypalOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-paypal-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const transition =
    TRANSITION_REQUEST_PAYMENT;
  const { deliveryMethod, shippingDetailsMaybe, quantity, payeeDetails, bookingDates, ...otherOrderParams} = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
    ...shippingDetailsMaybe,
      ...quantityMaybe
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams =
     {
        processAlias: config.transactionProcessAlias,
        transition,
        params: transitionParams
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };


   function money_round(num) {
     return num/ 100;
   }



    const lineItems = orderParams.speculatedTransaction.attributes.lineItems;
  const payinTotal = orderParams.speculatedTransaction.attributes.payinTotal
  const body = {
    subtotal: money_round(payinTotal.amount),
    item_total: money_round(lineItems.find(
      item => item.code === LINE_ITEM_UNITS && !item.reversal
    )?.lineTotal.amount),
    shipping: money_round(lineItems.find(
      item => item.code === LINE_ITEM_SHIPPING_FEE && !item.reversal
    )?.lineTotal.amount),
    tax:money_round(lineItems.find(
      item => item.code === LINE_ITEM_TAX && !item.reversal
    )?.lineTotal.amount),
    platform_fee:money_round(Math.abs(lineItems.find(
      item => item.code === LINE_ITEM_PROVIDER_COMMISSION && !item.reversal
    )?.lineTotal.amount)),
    currency: payinTotal.currency ,
    payee: payeeDetails}

    return createPaypalOrder(body).then(handleSuccess).catch(handleError);
};

export const initiateOrder = (orderParams, transactionId,paypalOrderId,successFn) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.

  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, shippingDetailsMaybe,quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
    paypalOrderId,
    shippingDetailsMaybe,
    quantityMaybe
  };

  // Parameters for Flex API
  if(otherOrderParams.protectedData){
    otherOrderParams.protectedData.paypalOrderId = paypalOrderId;
  }else{
    otherOrderParams.protectedData = {paypalOrderId: paypalOrderId};
  }

  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: transitionParams,
    }
    : {
      processAlias: config.transactionProcessAlias,
      transition,
      params: transitionParams,
    };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));

    if(successFn){
      successFn({transaction: order});
    }
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const fetchPaypalOrder = orderParams => (dispatch, getState, sdk) => {
  dispatch(getPaypalOrderRequest());
  const {paypalOrderId} = orderParams;

  const handleSuccess = response => {
    dispatch(getPaypalOrderSuccess(response));
    return response;
  };
  const handleError = e => {
    dispatch(getPaypalOrderError(storableError(e)));

    throw e;
  };
  return getPaypalOrder({paypalOrderId})
    .then(handleSuccess)
    .catch(handleError)

}

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());
  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {
      protectedData:
        {
          paypalTransactionId: orderParams.paypalTransactionId
        }
      },
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const expirePayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(expirePaymentRequest());
  console.log("expire payment");
  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CANCEL_PAYMENT,
    params: {
      protectedData:
        {
          paypalTransactionId: orderParams.paypalTransactionId
        }
    },
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(expirePaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(expirePaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'expire-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
    : {
        processAlias: config.transactionProcessAlias,
        transition,
        params: transitionParams,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const paypalCustomer = (params) => (dispatch, getState, sdk) => {

  dispatch(paypalCustomerRequest());
  return dispatch(fetchCurrentUser()).then(response => {
    dispatch(setInitialValues());
    const currentUser = getState().user.currentUser;

    dispatch(tryGetMerchantStatus()).then(res => {
      if ((getState().paypal.merchantIdQueried && !getState().paypal.paypalOnboarded)) {
        dispatch(paypalCustomerSuccess());
      }
    }).catch(e => {
      dispatch(paypalCustomerError(storableError(e)));
    });

    //get seller paypal info:
    sdk.listings.show({id: params.id, include: ['author']}).then(resp => {
      const listing = resp.data.data;
      dispatch(tryGetSellerStatus({userId:listing.relationships.author.data.id})).then(res => {
        if ((getState().paypal.seller.merchantIdQueried && !getState().paypal.seller.paypalOnboarded)) {
          dispatch(paypalSellerSuccess());
        }
      }).catch(e => {
        dispatch(paypalSellerError(storableError(e)));
      });
    });

  });

};
