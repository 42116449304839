
const paypalRootUrl = process.env.REACT_APP_PAYPAL_USE_SANDBOX_APIS ?
  process.env.REACT_APP_PAYPAL_SANDBOX_BASE_URL : process.env.REACT_APP_PAYPAL_BASE_URL;

const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const paypalApis =
  {
    AUTH_API_URL: (paypalRootUrl + "/v1/oauth2/token"),
    ORDERS_API_URL: (paypalRootUrl + "/v2/checkout/orders"),
    CLIENT_TOKEN_URL: (paypalRootUrl + "/v1/identity/generate-token"),
    PARTNER_REFERRAL_API_URL: (paypalRootUrl + "/v2/customer/partner-referrals"),
    MERCHANT_INTEGRATION_API_URL: (paypalRootUrl +
      "/v1/customer/partners/" + process.env.REACT_APP_PAYPAL_PARTNER_ID + "/merchant-integrations")
  };

