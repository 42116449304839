import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';
import { ExternalLink } from '../index';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: July, 1st 2021</p>
      <h2>Overview</h2>
      <p>This website is operated by WeCycle Inc. (“WeCycle”). Throughout the site, the terms “we”, “us” and “our” refer to WeCycle. WeCycle offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including but not limited to users who are browsers, customers, vendors/merchants, and/or contributors of content.<br/><br/>
        Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service. Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes. WeCycle is hosted by Sharetribe Oy (Business ID 2432359-2), a limited liability company incorporated and existing under the laws of Finland, having its principal place of business at Erottajankatu 19 B, 00130 Helsinki, Finland ("Sharetribe"). Sharetribe provides us with the online platform that allows us to provide our services to you.</p>
      <h2>1.  GENERAL TERMS AND CONDITIONS</h2>
      <p>
        By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.<br/><br/>
        You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate termination of your Services. We reserve the right to refuse service to anyone for any reason at any time.You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.<br/><br/>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
      </p>
      <h2>2.  ABILITY TO ACCEPT TERMS </h2>
      <p>
        You affirm that you are either more than 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms of Use, and to abide by and comply with these Terms of Use. In any case, you affirm that you are over the age of 13, as WeCycle is not intended for children under 13. If you are under 13 years of age, then please do not use WeCycle.
      </p>
      <h2>3.  PRODUCTS AND/OR SERVICES </h2>
      <p>
        We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis.<br/><br/>
        We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
      </p>
      <h2>4.  ACCURACY OF BILLING AND ACCOUNT INFORMATION</h2>
      <p>
        We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel purchases made on WeCycle. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.<br/><br/>
        We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors. You hereby agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
      </p>
      <h2>5.  OPTIONAL TOOLS</h2>
      <p>
        We may provide you with access to third-party tools over which we neither monitor nor have any control nor input. You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.<br/><br/>
        Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s). We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
      </p>
      <h2>6.  THIRD-PARTY LINKS</h2>
      <p>
        Certain content, products and services available via our Service may include materials from third-parties. Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
      </p>
      <h2>7.  USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>
      <p>
        We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments. We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.<br/><br/>
        We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.<br/><br/>
        You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
      </p>
      <h2>8.  PERSONAL INFORMATION</h2>
      <p>Your submission of personal information through the store is governed by our Privacy Policy. Please view our Privacy Policy.</p>
      <h2>9.  ERRORS, INACCURACIES AND OMISSIONS</h2>
      <p>
        Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).<br/><br/>
        We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
      </p>
      <h2>10.  PROHIBITED USES</h2>
      <p>
        In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
      </p>
      <h2>11.  DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>
      <p>
        We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable. You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.<br/><br/>
        You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.<br/><br/>
        In no event shall WeCycle, its directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some provinces/states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such provinces/states or jurisdictions, our liability shall be limited to the maximum extent permitted by law. The foregoing shall apply to the fullest extent permitted by law in the applicable jurisdiction.
      </p>
      <h2>12.  INDEMNIFICATION</h2>
      <p>
        You agree to indemnify, defend and hold harmless WeCycle and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party. This defense and indemnification obligation will survive these Terms of Service and your use on WeCycle.
      </p>
      <h2>13.  PURCHASE AND SALE TRANSACTIONS</h2>
      <p>You assume certain risks in using a marketplace service such as WeCycle. WeCycle is not involved with, nor provides a warranty for, any transaction between Buyer and Seller, nor has title to any items, and is not the Buyer or Seller in any transaction. You as the Buyer and/or Seller assume, agree, and understand you bear all of the risks in selling or purchasing items on our Service. </p>
      <h2>14.  FEES</h2>
      <p>Listing on WeCycle is free, however, WeCycle charges a fee for transactions through your use of the Service, which is 10%, inclusive of processing fees, but not shipping fees. All amounts are quoted in CAD dollars unless otherwise stated. WeCycle prohibits the listing or sale of any item that is illegal to sell under any applicable law, statute, ordinance, or regulation. </p>
      <h2>15.  BUYERS AND SELLERS</h2>
      <p>Sellers hereby agree that you have all rights necessary to sell the items you make available, and will describe such items truthfully, accurately, and completely. Buyers are solely responsible for reading and reviewing the item listing before purchasing. The contract is of purchase and sale is between the Seller and Buyer, not WeCycle.<br/><br/>
        Buyers may offer to purchase items from Sellers, and once accepted, this is considered a purchase, and a binding contract. Upon purchasing an item, a Buyer is obligated to remit payment for the item to WeCycle including any shipping or additional fees listed. WeCycle is not an auctioneer, nor is it a Seller or carrier. Service includes pricing, listing, and shipping assistance, but WeCycle is not a Buyer or Seller of items. WeCycle may delay any Purchase for purposes of fraud detection or to protect users from other illegal or wrongful activities. All Purchases are final, with no returns, refunds, cancellations, or retractions allowed, except where (a) Buyer cancels an order within three (3) hours of purchase, or (b) the Purchase is canceled where explicitly permitted by WeCycle or where applicable, a Seller.
      </p>
      <h2>16.  SHIPPING AND TAXES</h2>
      <p>
        WeCycle Shop will provide Seller with Buyer’s shipping address to ship the item to Buyer. Sellers are responsible for any shipping costs incurred with respect to their item. Seller must ship the item to Buyer and provide a tracking number within five (5) days of purchase. If Seller does not ship item, WeCycle will notify Seller and provide a set amount of time to ship item before the order is canceled and Buyer is refunded. Seller is fully responsible for contents of parcel, comply with all terms and comply with all conditions of the shipping carrier. Title transfers to Buyer upon shipment. If an item is lost, damaged, late, or arrives not as expected to Buyer, Buyer must report issue to WeCycle three (3) days after delivery. Once Buyer confirms receipt and acceptance of item, either by affirmatively notifying WeCycle or failing to notify WeCycle within three (3) days of delivery (as determined by tracking information), WeCycle will credit Seller’s account with amount equal to Purchase price, minus WeCycle’s transaction fee and any other applicable fees, if necessary.<br/><br/>
        Purchased items may be subject to applicable taxes in your jurisdiction. Please note that taxes are not included in the listed price for items, but may be displayed before confirmation of purchase. Taxes may be subject to change if final sale price is different than original price. Taxes are based on several factors, including price, location, and province/state, local, federal, or other applicable rates at time of purchase. Although we may assess taxes upon purchase or sale, you are ultimately responsible for the verification and reporting of any and all applicable taxes to the appropriate tax authorities, including instances where WeCycle does not collect taxes on your behalf.
      </p>
      <h2>17.  PAYMENTS</h2>
      <p>
        Buyers may pay for items using payment methods approved by WeCycle who shall receive payment through the platform. By submitting payment information to WeCycle, you authorize WeCycle to store that information and with your confirmation, charge you for any items purchased.
      </p>
      <h2>18.  RETURNS</h2>
      <p>
        Unless as otherwise provided herein, WeCycle Shop does not allow returns. If an item is not as described on the Service, you the Buyer, are required to initially attempt to resolve the issue by contacting us at info@wecycleshop.com within three days after determined delivery of the item based off tracking information. WeCycle Shop requires evidence of an attempted resolution. WeCycle Shop shall have final determination regarding the accuracy of item descriptions and has the sole authority to determine whether a refund is or is not warranted. Buyer and Seller understand and agree that they will abide by any final determinations made by WeCycle Shop in respect to any such issues and returns.
      </p>
      <h2>19.  DISCLAIMER OF WARRANTIES</h2>
      <p>
        Your use of the Service is at your sole risk. The Service is provided on an “as is” and “as available” basis. To the maximum extent permitted by applicable laws, WeCycle expressly disclaims all warranties of any kind, whether express, implied or statutory, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. To the maximum extent permitted by applicable laws, WeCycle makes no warranty that (1) you will be able to sell or purchase any items through the Service or that the Service will otherwise meet your requirements, (2) the Service will be uninterrupted, timely, secure, or error-free, (3) the results that may be obtained from the use of the Service will be accurate or reliable, or (4) the quality of any goods, services, information, or other material purchased or obtained by you through the Service will meet your expectations.<br/><br/>
        WeCycle makes no guarantee regarding the authenticity, quality, safety, or legality of any items bought or sold, nor the truth or accuracy of any listings, or ability of Buyers and Sellers to transact any business on the platform.
      </p>
      <h2>20.  SEVERABILITY</h2>
      <p>
        In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
      </p>
      <h2>21.  TERMINATION</h2>
      <p>
        The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
      </p>
      <h2>22.  ENTIRE AGREEMENT</h2>
      <p>
        The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service). Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
      </p>
      <h2>23.  GOVERNING LAW</h2>
      <p>
        These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Canada.
      </p>
      <h2>24.  CHANGES TO TERMS OF SERVICE</h2>
      <p>
        You can review the most current version of the Terms of Service at any time at this page.<br/><br/>
        WeCycle reserves the right, at their sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
      </p>
      <h2>25.  CONTACT INFORMATION</h2>
      <p>
        Questions about the Terms of Service should be sent to us at <ExternalLink href="mailto:info@wecycleshop.com">info@wecycleshop.com</ExternalLink>.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
