import React, { useState } from 'react';
import { array, bool, func, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  enablePaypalOnboarding, getPaypalOnboardingLinkFunc,
  offboardPaypalAccount,
  PAYPAL_ONBOARDING_REDIRECT_URL,
} from '../../ducks/paypalOnboarding.duck';

import {
  NamedRedirect,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  StripeConnectAccountStatusBox,
  StripeConnectAccountForm,
  UserNav,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import PaypalOnboarding from '../../components/PaypalOnboarding/PaypalOnboarding'
import { savePayoutDetails } from './PaypalSettingsPage.duck';

import css from './PaypalSettingsPage.module.css';
import forEach from 'lodash/forEach';
import queryString from 'query-string';


// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes) => {
  const path = createResourceLocatorString(
    'PaypalSettingsPage',
    routes,
    { returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
}; //

export const PaypalSettingsPageComponent = props => {

  const {
    currentUser,
    scrollingDisabled,
    merchantId,
    requestOnboardingLinks,
    requestOnboardingLinkInProgress,
    requestOnboardingLinksError,
    merchantIdInProgress,
    merchantIdError,
    merchantIdQueried,
    paypalOnboarded,
    isPaymentsReceivable,
    isPrimaryEmailValidated,
    isExpressCheckoutActive,
    hasOAuthPermissions,
    params,
    intl,

  } = props;

  const { returnURLType } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const [showOffboardPaypalModal,setOffboardPaypalModal] = useState(false);
  const queryParms = queryString.parse(props.location.search);

  const title = intl.formatMessage({ id: 'PaypalSettingsPage.title' });

  const formDisabled = merchantIdInProgress || requestOnboardingLinkInProgress;
  const rootURL = config.canonicalRootURL;
  const dispatch = useDispatch();
  const routes = routeConfiguration();
  const successURL = createReturnURL(PAYPAL_ONBOARDING_REDIRECT_URL, rootURL, routes);
  const paypalInfoQueried = merchantIdQueried && requestOnboardingLinksError;
  const partnerConfigOverride = {"partner_config_override": {
    "partner_logo_url": "TBD",
      "return_url": successURL,
      "return_url_description": "the url to return the merchant after the paypal onboarding process."}};

  let action_url = "";
  if(requestOnboardingLinks){
    requestOnboardingLinks.map(t => {
        if (t.rel === "action_url") {
            action_url = t.href;
        }
      }
    )

  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="PaypalSettingsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
            showOffboardPaypalModalValue={showOffboardPaypalModal}
            onCloseModal={() => {

              setOffboardPaypalModal(false)

            }}
            offboardAccount={()=>{

              setOffboardPaypalModal(false);
              dispatch(offboardPaypalAccount());
              dispatch(getPaypalOnboardingLinkFunc(currentUser.id.uuid));

            }}
          />
          <UserNav selectedPageName="PaypalSettingsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="PaypalSettingsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="PaypalSettingsPage.heading" />
            </h1>
        <h3><FormattedMessage id={"PaypalSettingsPage.overview"}/></h3>
            <PaypalOnboarding action_url={action_url}
                              merchantId={merchantId}
                              paypalOnboarded={paypalOnboarded}
                              paypalInfoQueried={paypalInfoQueried}
                              isPaymentsReceivable={isPaymentsReceivable}
                              isPrimaryEmailValidated={isPrimaryEmailValidated}
                              isExpressCheckoutActive={isExpressCheckoutActive}
                              hasOAuthPermissions={hasOAuthPermissions}
                              merchantIdQueried={merchantIdQueried}
                              showOffboardingModal={() => {
                                setOffboardPaypalModal(true)

                              }}

                              showOffboardingModalValue={showOffboardPaypalModal}

                              setOffboardingFalse={() => {
                                dispatch(enablePaypalOnboarding()); return true
                                }}


            >offboarding status? {showOffboardPaypalModal}</PaypalOnboarding>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PaypalSettingsPageComponent.defaultProps = {
  currentUser: null,
  merchantId: null,
  requestOnboardingLinks: null,
  requestOnboardingLinkInProgress:false,
  requestOnboardingLinksError: false,
  merchantIdInProgress: false,
  merchantIdError: false,
  merchantIdQueried: false,
  paypalOnboarded:false,
  isPaymentsReceivable:false,
  isPrimaryEmailValidated:false,
  isExpressCheckoutActive:false,
  hasOAuthPermissions:false,
};

PaypalSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  merchantId: string,
  requestOnboardingLinks: array,
  requestOnboardingLinkInProgress: bool.isRequired,
  requestOnboardingLinksError: bool.isRequired,
  merchantIdInProgress: bool.isRequired,
  merchantIdError: bool.isRequired,
  merchantIdQueried: bool.isRequired,
  scrollingDisabled: bool.isRequired,
  paypalOnboarded: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    merchantId,
    requestOnboardingLinks,
    requestOnboardingLinkInProgress,
    requestOnboardingLinksError,
    merchantIdInProgress,
    merchantIdError,
    merchantIdQueried,
    isPaymentsReceivable,
    isPrimaryEmailValidated,
    isExpressCheckoutActive,
    hasOAuthPermissions,
  } = state.paypal;

  const { currentUser } = state.user;
  const paypalOnboarded = state.user.currentUser?.attributes.profile.privateData.paypalOnboarded || state.paypal.paypalOnboarded;
  return {
    currentUser,
    merchantId,
    requestOnboardingLinks,
    requestOnboardingLinkInProgress,
    requestOnboardingLinksError,
    merchantIdInProgress,
    merchantIdError,
    merchantIdQueried,
    paypalOnboarded,
    isPaymentsReceivable,
    isPrimaryEmailValidated,
    isExpressCheckoutActive,
    hasOAuthPermissions,
    scrollingDisabled: isScrollingDisabled(state),
  };
};


const PaypalSettingsPage = compose(
  connect(
    mapStateToProps
  ),
  injectIntl
)(PaypalSettingsPageComponent);

export default PaypalSettingsPage;
