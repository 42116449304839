import uuid from 'react-uuid';
import config from '../config';
import paypalApis from '../config/paypal-config';
import { getKlaviyoProfile, trackKlaviyoEvent, updateKlaviyoProfile } from '../util/api';

//========THUNKS======//


//======KLAVIYO SDK======//

export class KlaviyoSdk {

  static createUser = (user) => {
   // console.log("Creating user!");
    //try get klaviyo user without klaviyo id
    const klaviyoId= user.currentUser.attributes.profile.privateData.klaviyoId;
    const body = {
        "data": {
          "type": "profile",

          "attributes":
            {
              "email": user.currentUser.attributes.email,
              "phone_number": user.currentUser.attributes.phoneNumber,
              "first_name": user.currentUser.attributes.profile.firstName,
              "last_name":  user.currentUser.attributes.profile.lastName,
            }
        }
    };
    updateKlaviyoProfile({requestBody: body,klaviyoId: klaviyoId}).then(res => {
          if(res.status === 200){
          //  console.log("Successfully updated profile", res);
            return res.data;
          }else{
        //    console.log("Something went wrong updating klaviyo User",res);
            return false;
        }
      }).catch(e => {
       // console.log("Could not create Klaviyo User.",e);
        return false;
      })


  }

  static identifyUser = (sdk,user) => {
   // console.log("identifyUser", user?.currentUser);
    if(!user?.currentUser){ console.log("No user!"); return false; }
    this.createUser(user);


}

  static trackEvent =(email, action) => {
   // console.log("Track event.",email);
    const metric = this.getMetric(action);
    const properties = this.getProperties(action);
    const body = {
      "data": {
        "type": "event",
        "attributes":
          {
            "profile": { "email": `${email}` },
            "metric": metric,
            "properties": properties

          }
      }
    };

    trackKlaviyoEvent(body).then(res => {
    //  console.log("success in event tracking", res);
    }).catch(e=>{
     // console.log("Failure in event tracking",e);
    })
  }

  static getMetric = action => {
   // console.log("Get Metric Action", action);
    return {
      "name": "viewPage_"+action.payload.location.pathname,

    }
}
  static getProperties = action => {
   // console.log("Get properties Action", action);
    return {
      "value": 1,

    }
  }
}


