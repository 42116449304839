import pick from 'lodash/pick';
import {
  tryGetMerchantStatus,
  getPaypalOnboardingLinkFunc
} from '../../ducks/paypalOnboarding.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { resolve } from 'url';
import queryString from 'query-string';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/PaypalSettingsPage/SET_INITIAL_VALUES';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});


// ================ Thunks ================ //
//THIS SHOULD BE THE WHERE I PUT THE PAYPAL LOADING
export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.


  return dispatch(fetchCurrentUser()).then(response => {
    dispatch(setInitialValues());
    const currentUser = getState().user.currentUser;

        dispatch(tryGetMerchantStatus()).then(res => {
        if((getState().paypal.merchantIdQueried && !getState().paypal.paypalOnboarded)){
          dispatch(getPaypalOnboardingLinkFunc(currentUser.id.uuid));

        }
    }).catch(e => {

        });

    return response;
  });
};
