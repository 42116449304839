import { storableError } from '../util/errors';
import * as log from '../util/log';
import config from '../config/config';
import paypalApis from '../config/paypal-config';
import { getPaypalMerchantStatus } from '../util/api';
import { getPaypalOnboardingLink } from '../util/api';
import { getPaypalOnboardingStatus } from '../util/api';
import uuid from 'react-uuid';
import { bool } from 'prop-types';

const PAYPAL_PI_HAS_PASSED_CONFIRM = ['processing', 'requires_capture', 'canceled', 'succeeded'];

// ================ Action types ================ //


export const PAYPAL_ACCOUNT_CLEAR_ERROR = 'app/paypal/PAYPAL_ACCOUNT_CLEAR_ERROR';
export const PAYPAL_ONBOARDING_REDIRECT_URL = 'account/paypal';
export const GET_PAYPAL_ACCESS_TOKEN = 'app/paypal/GET_PAYPAL_ACCESS_TOKEN';

export const RETRIEVE_PAYPAL_ONBOARDING_LINK_REQUEST = 'app/paypal/RETRIEVE_PAYPAL_ONBOARDING_LINK_REQUEST';

export const PAYPAL_ONBOARDING_LINK_SUCCESS = 'app/paypal/RETRIEVE_PAYPAL_ONBOARDING_LINK_ERROR';
export const PAYPAL_ONBOARDING_LINK_ERROR = 'app/paypal/RETRIEVE_PAYPAL_ONBOARDING_LINK_ERROR';
export const PAYPAL_GET_MERCHANT_ID_SUCCESS = 'app/paypal/PAYPAL_GET_MERCHANT_ID_SUCCESS';
export const PAYPAL_GET_MERCHANT_ID_REQUEST = 'app/paypal/PAYPAL_GET_MERCHANT_ID_REQUEST';
export const PAYPAL_GET_SELLER_MERCHANT_ID_REQUEST ='app/paypal/PAYPAL_GET_SELLER_MERCHANT_ID_REQUEST';
export const PAYPAL_GET_MERCHANT_ID_ERROR = 'app/paypal/PAYPAL_GET_MERCHANT_ID_ERROR';
export const PAYPAL_REDIRECT_RELATIVE_LINK = 'account/paypal';
export const UPDATE_USER_MERCHANT_ID_DONE = 'account/UPDATE_MERCHANT_ID_DONE';
export const PAYPAL_GET_SELLER_MERCHANT_ID_SUCCESS = 'app/paypal/PAYPAL_GET_SELLER_MERCHANT_ID_SUCCESS';
export const UPDATE_SELLER_MERCHANT_ID_DONE = 'account/UPDATE_SELLER_MERCHANT_ID_DONE';
export const PAYPAL_GET_SELLER_MERCHANT_ID_ERROR = 'app/paypal/PAYPAL_GET_SELLER_MERCHANT_ID_ERROR';
export const PAYPAL_ENABLE_ONBOARDING_REQUEST = 'app/paypal/ENABLE_ONBOARDING_REQUEST';
export const PAYPAL_ENABLE_ONBOARDING_SUCCESS = 'app/paypal/ENABLE_ONBOARDING_SUCCESS';
export const PAYPAL_ENABLE_ONBOARDING_ERROR = 'app/paypal/ENABLE_ONBOARDING_ERROR';
export const PAYPAL_OFFBOARDING_REQUEST = 'app/paypal/OFFBOARDING_REQUEST';
export const PAYPAL_OFFBOARDING_SUCCESS = 'app/paypal/OFFBOARDING_SUCCESS';
export const PAYPAL_OFFBOARDING_ERROR = 'app/paypal/OFFBOARDING_ERROR';

// ================ Reducer ================ //

const initialState = {
  merchantId: null,
  requestOnboardingLinks: null,
  requestOnboardingLinkInProgress:false,
  requestOnboardingLinksError: false,
  merchantIdInProgress: false,
  merchantIdError: false,
  merchantIdQueried: false,
  paypalOnboarded: false,
  seller: {}

};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case PAYPAL_ACCOUNT_CLEAR_ERROR:
      return { ...initialState };
    case PAYPAL_ENABLE_ONBOARDING_REQUEST:
      return {...state,
        enableOnboardingInProgress:true,
      }
    case PAYPAL_ENABLE_ONBOARDING_ERROR:
      return {...state,
        enableOnboardingError:true,
        enableOnboardingInProgress:false
      }
    case PAYPAL_ENABLE_ONBOARDING_SUCCESS:
      return {...state,
        enableOnboardingInProgress:false
      }
    case PAYPAL_OFFBOARDING_REQUEST:
      return {...state,
        offboardingInProgress:true,
      }
    case PAYPAL_OFFBOARDING_ERROR:
      return {...state,
        offboardingError:true,
        offboardingInProgress:false
      }
    case PAYPAL_OFFBOARDING_SUCCESS:
      return {...state,
        paypalOnboarded:false,
        merchantId:null
      }
    case RETRIEVE_PAYPAL_ONBOARDING_LINK_REQUEST:
      return {...state,
        requestOnboardingLinkInProgress:true,
      }

    case PAYPAL_ONBOARDING_LINK_SUCCESS:
      return {...state,
        requestOnboardingLinkInProgress:false,
        requestOnboardingLinks: payload,
        requestOnboardingLinksError: false
      }
    case PAYPAL_ONBOARDING_LINK_ERROR:
      return {...state,
        requestOnboardingLinkInProgress:false,
        requestOnboardingLinks: null,
        requestOnboardingLinksError: true
      }

    case PAYPAL_GET_MERCHANT_ID_REQUEST:
      return {...state,
        merchantIdInProgress:true,
      }
    case PAYPAL_GET_SELLER_MERCHANT_ID_REQUEST:
      return {...state,
    seller:{merchantIdInProgress:true}
      }

    case PAYPAL_GET_MERCHANT_ID_ERROR:

      return {...state,
        merchantIdInProgress:false,
        merchantIdError: true,
        merchantIdQueried:true
      }

    case PAYPAL_GET_MERCHANT_ID_SUCCESS:

      return {...state,
        merchantIdInProgress:false,
        merchantIdError: false,
        merchantIdQueried: true,
        merchantId: payload.merchantId,
        paypalOnboarded: payload.paypalOnboarded,
        isPaymentsReceivable: payload.isPaymentsReceivable,
        isPrimaryEmailValidated: payload.isPrimaryEmailValidated,
        isExpressCheckoutActive: payload.isExpressCheckoutActive,
        hasOAuthPermissions: payload.hasOAuthPermissions,
      }

    case UPDATE_USER_MERCHANT_ID_DONE:
      return {...state,
        merchantIdInProgress:false,
        merchantIdError: false,
        merchantIdQueried: true,
        merchantId: payload.merchantId,
        paypalOnboarded: payload.paypalOnboarded
      }

    case PAYPAL_GET_SELLER_MERCHANT_ID_SUCCESS:
      return {...state,
        seller: {
          merchantIdInProgress:false,
          merchantIdError: false,
          merchantIdQueried: true,
          ...payload
      }
      }

    case UPDATE_SELLER_MERCHANT_ID_DONE:
      return {...state,
        seller: {...state.seller,
          merchantIdInProgress: false,
          merchantIdError: false,
          merchantIdQueried: true,
          merchantId: payload.merchantId,
          paypalOnboarded: payload.paypalOnboarded
        }
      }

    case PAYPAL_GET_SELLER_MERCHANT_ID_ERROR:
      return {...state, seller: {...state.seller,
        merchantIdInProgress: false,
        merchantIdError: false,
        merchantIdQueried: true
        }};

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const paypalAccountClearError = () => ({
  type: PAYPAL_ACCOUNT_CLEAR_ERROR,
});

export const getPaypalAccessToken = () => ({
  tupe: GET_PAYPAL_ACCESS_TOKEN,
})

export const retrievePaypalOnboardingLinkRequest = () => ({
  type: RETRIEVE_PAYPAL_ONBOARDING_LINK_REQUEST,
})

export const paypalOnboardingLinkSuccess
  = (payload) => ({
    type: PAYPAL_ONBOARDING_LINK_SUCCESS,
  payload:payload
  })

export const paypalOnboardingLinkError
  = () => ({
  type: PAYPAL_ONBOARDING_LINK_ERROR,
})

export const tryGetMerchantIdRequest = () => ({
  type: PAYPAL_GET_MERCHANT_ID_REQUEST,
})
export const tryGetSellerMerchantIdRequest = () => ({
  type: PAYPAL_GET_SELLER_MERCHANT_ID_REQUEST,
})

export const tryGetMerchantIdSuccess
  = (payload) => ({
  type: PAYPAL_GET_MERCHANT_ID_SUCCESS,
  payload:payload
})

export const tryGetMerchantIdError
  = () => ({
  type: PAYPAL_GET_MERCHANT_ID_ERROR,
})

export const tryGetSellerMerchantIdSuccess
  = (payload) => ({
  type: PAYPAL_GET_SELLER_MERCHANT_ID_SUCCESS,
  payload:payload
})

export const tryGetSellerMerchantIdError
  = () => ({
  type: PAYPAL_GET_SELLER_MERCHANT_ID_ERROR,
})

export const enablePaypalOnboardingError
  = () => ({
  type: PAYPAL_ENABLE_ONBOARDING_ERROR,
})

export const enablePaypalOnboardingSuccess
  = () => ({
  type: PAYPAL_ENABLE_ONBOARDING_SUCCESS,
})
export const enablePaypalOnboardingRequest
  = () => ({
  type: PAYPAL_ENABLE_ONBOARDING_REQUEST,
})

export const PaypalOffboardingError
  = () => ({
  type: PAYPAL_OFFBOARDING_ERROR,
})

export const PaypalOffboardingSuccess
  = () => ({
  type: PAYPAL_OFFBOARDING_SUCCESS,
})
export const PaypalOffboardingRequest
  = () => ({
  type: PAYPAL_OFFBOARDING_REQUEST,
})


export const updateUserMerchantIdDone
  = (payload) => ({
  type: UPDATE_USER_MERCHANT_ID_DONE,
  payload:payload
})

export const updateSellerMerchantIdDone
  = (payload) => ({
  type: UPDATE_SELLER_MERCHANT_ID_DONE,
  payload:payload
})




// ================ Thunks ================ //

export const tryGetSellerStatus = params => (dispatch, getState, sdk) => {
  dispatch(tryGetSellerMerchantIdRequest());

  return getPaypalMerchantStatus({ trackingId: params.userId.uuid }).then(response => {//get onboarding staus
    if(response) {
      return getPaypalOnboardingStatus({ merchantId:response?.merchant_id,updateUserMaybe:false }).then(respOnboarding => {
        const payload = {
          merchantId: response?.merchant_id,
          id: params.userId,
          paypalOnboarded: respOnboarding?.paypalOnboarded,
          isPaymentsReceivable: respOnboarding?.isPaymentsReceivable,
          isPrimaryEmailValidated: respOnboarding?.isPrimaryEmailValidated,
          isExpressCheckoutActive: respOnboarding?.isExpressCheckoutActive,
          hasOAuthPermissions: respOnboarding?.hasOAuthPermissions
        };
        dispatch(tryGetSellerMerchantIdSuccess(payload));
        dispatch(updateSellerMerchantIdDone(payload));

      }).catch(e => {
        return dispatch(tryGetSellerMerchantIdError(storableError(e)));
      });
    }
  }).catch(e => {
      return dispatch(tryGetSellerMerchantIdError(storableError(e)));
    }
  )
};

export const enablePaypalOnboarding = params  => (dispatch, getState, sdk) => {
  dispatch(enablePaypalOnboardingRequest());
  sdk.currentUser.updateProfile({ privateData: { paypalOffboarded: false } }).then(resp=>{
    dispatch(enablePaypalOnboardingSuccess());
  }).catch(e=>{
    enablePaypalOnboardingError(storableError(e));
  })
}

export const offboardPaypalAccount = params  => (dispatch, getState, sdk) => {
  dispatch(PaypalOffboardingRequest());
  sdk.currentUser.updateProfile({ privateData: { paypalOffboarded: true, merchantId:null, paypalOnboarded:false} }).then(resp=>{
    dispatch(PaypalOffboardingSuccess());
  }).catch(e=>{
    PaypalOffboardingError(storableError(e));
  })
}

export const tryGetMerchantStatus = params => (dispatch, getState, sdk) => {
  dispatch(tryGetMerchantIdRequest());
  const user = getState().user;
  const trackingId = user.currentUser.id.uuid;
  const merchantId = user.currentUser.attributes.profile.privateData.merchantId;
  const paypalOnboarded = user.currentUser.attributes.profile.privateData.paypalOnboarded;
  if(user.currentUser.attributes.profile.privateData.paypalOffboarded){
    const payload = {
      paypalOffboarded:true,
      paypalOnboarded: false,
      merchantId:null,
      isPaymentsReceivable: false,
      isPrimaryEmailValidated: false,
      isExpressCheckoutActive: false,
      hasOAuthPermissions: false
    };
    dispatch(tryGetMerchantIdSuccess(payload));
    dispatch(updateUserMerchantIdDone(payload));
    return new Promise((resolve, reject)=>{
      resolve({});
    });
  }
  else if (merchantId) {
    return getPaypalOnboardingStatus({ merchantId,updateUserMaybe:true}).then(respOnboarding => {
      const payload = {
        merchantId,
        paypalOnboarded: respOnboarding?.paypalOnboarded,
        isPaymentsReceivable: respOnboarding?.isPaymentsReceivable,
        isPrimaryEmailValidated: respOnboarding?.isPrimaryEmailValidated,
        isExpressCheckoutActive: respOnboarding?.isExpressCheckoutActive,
        hasOAuthPermissions: respOnboarding?.hasOAuthPermissions
      };

      dispatch(tryGetMerchantIdSuccess(payload));
      dispatch(updateUserMerchantIdDone(payload));
    }).catch(e=>{
      return dispatch(tryGetMerchantIdError(storableError(e)));
    })
  } else {
    return getPaypalMerchantStatus({ trackingId }).then(response => {//get onboarding staus
      if(response) {
        return getPaypalOnboardingStatus({ merchantId:response?.merchant_id,updateUserMaybe:true }).then(respOnboarding => {
          const payload = {
            merchantId: response?.merchant_id,
            paypalOnboarded: respOnboarding?.paypalOnboarded,
            isPaymentsReceivable: respOnboarding?.isPaymentsReceivable,
            isPrimaryEmailValidated: respOnboarding?.isPrimaryEmailValidated,
            isExpressCheckoutActive: respOnboarding?.isExpressCheckoutActive,
            hasOAuthPermissions: respOnboarding?.hasOAuthPermissions
          };
          dispatch(tryGetMerchantIdSuccess(payload));
          dispatch(updateUserMerchantIdDone(payload));

        }).catch(e => {
          return dispatch(tryGetMerchantIdError(storableError(e)));
        });
      }

  }).catch(e => {
      return dispatch(tryGetMerchantIdError(storableError(e)));
    }
  )
}
}

export const getPaypalOnboardingLinkFunc = params => (dispatch, getState) => {

    const currentUser = getState().user.currentUser;
    dispatch(retrievePaypalOnboardingLinkRequest());
  const trackingId = getState().user.currentUser.id.uuid;

  if(!trackingId){
    throw Error("Merchant ID for user was not created.");
  }

    return getPaypalOnboardingLink({trackingId}).then(response => {
        if (response.links) {
          dispatch(paypalOnboardingLinkSuccess(response.links));
          return response;

        } else {
          dispatch(paypalOnboardingLinkError(response));
          return response;
        }
      }
    ).catch(e => {
        dispatch(paypalOnboardingLinkError(storableError(e)));
        return e;
      }
    );

}



