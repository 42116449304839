import TagManager from 'react-gtm-module';


export class LoggingAnalyticsHandler {
  trackPageView(url) {

  }
}

// Google Analytics 4 (GA4) using gtag.js script, which is included in server/rendered.js
// Note: the script is only available locally when running "yarn run dev-server"
export class GoogleAnalyticsHandler {
  trackPageView(canonicalPath, previousPath) {
    // GA4 property. Manually send page_view events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // Note 1: You should turn "Enhanced measurement" off.
    //         It attaches own listeners to elements and that breaks in-app navigation.
    // Note 2: If previousPath is null (just after page load), gtag script sends page_view event automatically.
    //         Only in-app navigation needs to be sent manually from SPA.
    // Note 3: Timeout is needed because gtag script picks up <title>,
    //         and location change event happens before initial rendering.
    if (previousPath) {
      console.log("tag manager triggered");
      window.setTimeout(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'page_view', // event name declared during initialization
            pagePath: canonicalPath,
            // add more data you want here
          }
        });
      }, 300);
    }
  }
}

